
<template>
    <div class="NewProductCollect">
        <el-row>
            <el-col :span="24"   class="titbox">
                <el-col class="title" :span="8">新品收集</el-col>
                <el-col class="text-right  addBtn" :span="16" @click.native="addNewProduct">
                    添加商品
                </el-col>
            </el-col>
        </el-row>
        <div class="box" v-if="listArr.length > 0">
            <div  v-for="(item,index) of listArr" :key="index" class="listGoodsCom" @click="DialogFun(item)">
                <div class="imgBox">
                    <img  v-if="item.productPic" :src="item.productPic" alt="">
                    <img  v-else src="../../assets/noProductImg.png" alt="">
                </div>
                <div class="infoBox">
                <div class="name">{{item.productName}}</div>
                <div class="partBox">包装规格：{{item.productSpec}}</div>
                <div class="partBox">建议零售：￥{{item.suggestSalePrice}}</div>
                <!-- <div class="partBox">收集区域：{{item.partnerRegion}}</div> -->
                <div class="partBox">提交时间：{{item.createTime.substr(0,10)}}</div>
                <div class="desBox">生产企业：{{item.productCompany?item.productCompany:'-'}}</div>
                <div class="desBox">预计月销售量：{{item.preSaleCount==-1?'-':item.preSaleCount}}</div>
                </div>
            </div>
        </div>
        <div class="box" v-else>
            <img src="@/assets/addProduct.png" alt="">
            <div class="btnBox"><span @click="addNewProduct()">添加商品</span></div>
        </div>

        <el-dialog :title="titleMsg" :visible.sync="dialogFormVisible" width="36%">
            <el-form  
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                >
                <el-form-item label="商品名称"    prop="productName">
                    <el-input v-model="ruleForm.productName"  ref="productName" autocomplete="off"  placeholder="请填写商品名称" autofocus></el-input>
                </el-form-item>
                <el-form-item label="商品规格"   prop="productSpec">
                    <el-input v-model="ruleForm.productSpec" ref="productSpec" autocomplete="off"  placeholder="请填写商品规格"></el-input>
                </el-form-item>
                <el-form-item label="建议零售价"   prop="suggestSalePrice">
                    <el-input v-model="ruleForm.suggestSalePrice" ref="suggestSalePrice" autocomplete="off"  placeholder="请填写建议零售价"></el-input>
                </el-form-item>
                <!-- <el-form-item label="收集区域"   prop="partnerRegion">
                    <el-input v-model="ruleForm.partnerRegion" ref="partnerRegion" autocomplete="off"  placeholder="请填写收集区域"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="引进理由"  prop="bugReason">
                    <el-input type="textarea" v-model="ruleForm.bugReason" ref="bugReason" autocomplete="off"  placeholder="请输入内容"></el-input>
                </el-form-item> -->

                <el-form-item label="预计月销售量"   prop="preSaleCount">
                    <el-input v-model="ruleForm.preSaleCount" ref="preSaleCount" autocomplete="off"  placeholder="请填写预计月销售量"></el-input>
                </el-form-item>

                <el-form-item label="生产企业"   prop="productCompany">
                    <el-input v-model="ruleForm.productCompany" ref="productCompany" autocomplete="off"  placeholder="请填写生产企业"></el-input>
                </el-form-item>

                <el-form-item  label="药品图片"   prop="productPic">
                    <el-upload
                        :class="hideUpload?'hideBox':''"
                        action="#"
                        list-type="picture-card"
                        :limit="1"
                        :show-file-list="boolean"
                        :on-exceed="handleExceed"
                        :before-upload="beforeUpload"
                        :auto-upload="false"
                        :on-change="imgSaveToUrl"
                      
                        :on-remove="handleRemove"
                        :file-list="fileImgList"
                        ref="upload"
                    >
                    <!--   :on-preview="handlePictureCardPreview"   预览方法 -->
                        <img src="@/assets/uploadImg.png" alt="" />
                        <div class="el-upload__tip" slot="tip">
                        最多可上传<span
                            class="redMsg"
                            >1</span
                        >张图片
                        </div>
                    </el-upload>
                    <!-- 预览弹窗 -->
                    <!-- <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog> -->
                </el-form-item>
                <!--
                <el-form-item label="是否默认地址" prop="isDefaultAddress">
                    <el-switch v-model="ruleForm.isDefaultAddress"></el-switch>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button  @click="saveFun('ruleForm')">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Qs from "qs";
import { putFile } from "api/order.js";
// ,getlist,removeNewColl 
import { 
    newProCollect,
    getNewProductlist, // 商品信息 列表
} from "api/member.js";
export default {
  name: "NewProductCollect",
  inject:['reload'],
  data() {
      //表单验证
    let validateNumber = (rule, value, callback) => {
        const NumberReg =/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
        if (!value ) {
            return callback()
        }else{
            if (!NumberReg.test(value) ) {
                callback(new Error("请输入数字"));
            }else{
                return callback()
            }
        }
    };
    return {
        titleMsg:'',
        dialogFormVisible: false,//初始  新品收集为不显示
         //加载loading条
        loading: false,
        ruleForm: {
          appType:'00',   //app类型 00:3a 01: 团队管理 02: 药百万
          memberStoreId:'',//  门店ID
          productName:'',//商品名称
          productSpec:'',//商品规格
          suggestSalePrice:'',//建议零售价
        //   bugReason:'',//引进理由
          productCompany:'',//生产企业
          preSaleCount:'',//预计月销售量
          productPic:'',
          partnerRegion:'',//收集区域
        },
        rules: {
            // productName:[{required:true,message:"商品名称必填", trigger: "blur"},{ validator: validateName, trigger: "blur" }],
            productName:[{required:true,message:"商品名称必填", trigger: "blur"}],
            productSpec:[{required:true,message:"商品规格必填", trigger: "blur"}],
            suggestSalePrice:[{required:true,message:"建议零售价必填", trigger: "blur"},{ validator: validateNumber, trigger: "blur" }],
            // partnerRegion:[{required:true,message:"收集区域必填", trigger: "blur"}],
            preSaleCount:[{ validator: validateNumber, trigger: "blur" }],
        },
        fileImgList:[

        ],
        //是否展示图片列表
        boolean: true,
        dialogImageUrl: "",
        dialogVisible: false,
        hideUpload:false,

        listArr:[],
    };
  },
  methods: {


        InitFun(){
            let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
            getNewProductlist(memberStoreId).then(res=>{
                this.listArr=res.data.data
            })
        },

     //   新增添加商品弹框
     addNewProduct(){
           this.dialogFormVisible = true;
           this.titleMsg="商品上传"
    },

    onSubmit(){
        newProCollect(this.ruleForm).then((res) => {
          if (res.code === 200) {
            this.$message({
                message:"提交成功",
                type: 'success'
            });
            this.dialogFormVisible = false;
          } else {
            this.$message({
                message:res.msg,
                type: 'warning'
            });
          }
        });
    },
    // 保存
    saveFun(formName){
        // formName
        this.$refs[formName].validate((valid) => {
            if (valid) {
                let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
                this.ruleForm.memberStoreId=memberStoreId
                this.onSubmit()
                this.reload();
            } else {
                console.log('error submit!!');
                return false;
            }
        })
    },
    // 选取图片后自动回调，里面可以获取到文件
    imgSaveToUrl(file) {
      let fileName = file.name;
      let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
      if (regex.test(fileName.toLowerCase())) {
        this.posterURL = file.url;
      } else {
        this.fileImgList = this.fileImgList.splice(
          0,
          this.fileImgList.length - 1
        );
        this.$message.error("请选择图片文件");
      }
        let formfile = new FormData();
        formfile.append("file", file.raw);
        console.log(file.raw)
        putFile(formfile).then((data) => {
            this.ruleForm.productPic=data.data.data.link
            console.log('data.data.data',this.ruleForm.productPic)
        });
        this.hideUpload=true

    },
     //移除
     handleRemove(file) {
      // , fileList
      console.log('上传图片合集',this.fileImgList)
      this.fileImgList.forEach((item, i) => {
        if (item.name == file.name) {
          this.fileImgList.splice(i, 1);
        }
      });
      this.hideUpload=false
    },
    //预览
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    // },
    //定义超出限制时
    handleExceed() {
      this.$message.warning(
        `只能上传1张图片`
      );
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

  },
  created() {
      this.InitFun()
  },
  components: {
  },
};
</script>
<style lang="less" scoped>
.hideBox /deep/.el-upload--picture-card {
  display: none;
}
.titbox{
        padding: 20px;
        border-bottom:none;
    .title{
        border-left: 2px solid #FF3333;
        font-size: 16px;
        line-height: 22px;
        padding-left: 13px;
        color:#333333;
        font-weight: 600;
    }
    .addBtn{
            color: #409fe0;
            font-size: 13px;
            cursor: pointer;
    }
}

/deep/.el-col {
    padding: 0px;
}
/deep/.el-dialog{
    z-index: 2004;
}
    /deep/.el-dialog__header {
          background: #ECECEC;
          padding: 5px 10px;
          .el-dialog__title{
              font-size: 14px;
              font-weight: bold;
          }
          .el-dialog__headerbtn{
                top:8px
          }
    }
     /deep/.el-input__inner{
        line-height:30px;
        width:100%;
        height:30px;
    }
    /deep/.el-dialog__body{
        padding:22px 40px 0 40px;
    }
    /deep/.el-form-item{
        margin-bottom:10px
        
    }
    /deep/.el-form-item__label{
        width:26%;
        text-align: left;
        line-height:40px
    }

    /deep/.el-form-item__content{
        width:calc( 74% - 25px );
        float: left;
        margin-bottom: 16px;
    }

    /deep/.dialog-footer{
        text-align: left;
            padding:0px 20px 20px;
        .el-button{
            background: #ff3333;
            color: #fff;
            &:hover{
                background: #ff3333;
            color: #fff;
            }
        }
    }
.clear {
  clear: both;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 80px;
        height: 80px;
        float: left;
      }
      .el-upload.el-upload--picture-card img {
        width: 80px;
        height: 80px;
      }
      /deep/.el-upload--picture-card {
        background-color: #fff;
        border: 1px dashed #ccc;
        border-radius: 0px;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        line-height: 80px;
        vertical-align: top;
        color: #d8d8d8;
        float: left;
      }
      .el-upload__tip {
        font-size: 12px;
        color: #606266;
        line-height: 80px;
        margin-left: 20px;
        float: left;
      }



.box {
    padding:15px 0;
    img{
        display: block;
        width:30%;
        margin:0 auto;
        margin-top: 40px;
    }
}
.btnBox{
    width: 100%;
        span{
            margin: 0 auto;
            display:block;
            margin-top:20px;
            // background: #40bdc9;
            background: #ff3333;
            color: #fff;
            font-weight: 500;
            width:100px;
            height:40px;
            text-align: center;
            line-height:40px;
            font-size:16px;  
        }  
    }

.listGoodsCom {
    width:45%;
    margin-right:2%;
    padding: 10px;
    float: left;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top:8px;
    border: 1px solid #ccc;
    .imgBox {
      width:180px;
      height:180px;
      >img {
        height:100%;
        width:100%;
        margin: 0;
      }
      
    }

    .infoBox {
      margin-left:12px;
      text-align: left;

      .name {
        font-size: 16px;
        line-height:24px;
        color: #333333;
        font-weight: 600;
        margin-bottom:6px;
      }

      .partBox{
        font-size:14px;
        line-height:20px;
        font-weight: 500;
        color: #333333FF;
        margin-bottom:6px;
      }
      .desBox {
        font-size:13px;
        line-height:20px;
        color: #666666FF;
        // max-width:200px;
        margin-bottom: 6px;
      }

    }
}
</style>